<template>
    <v-container class="my-10 my-md-16" >
        <v-row class="d-flex flex-row pa-3 heading">
            <v-col>
                <h1 class="text-left">{{ $t('referral.title') }}<v-icon class="ml-2" color="#000000">group</v-icon></h1>
                <p class="text-left">
                    {{ $t('referral.desc') }}
                </p>
            </v-col>
        </v-row>

        <div class="card border rounded-xl mx-3 my-6 pa-3">
            <v-row class="py-4 px-3">
                <div class="d-block d-sm-flex col-12 col-md-8 py-0">
                    <v-list class="col-12 col-sm-6 py-0 py-sm-1">
                        <v-col>
                            <v-row justify="center" class="text-left">
                                <v-col cols="3" sm="3" md="3" class="text-label">
                                   {{ $t('referral.link') }}
                                </v-col>
                                <v-col cols="6" sm="6" md="9" class="text-right text-sm-left text-container text-disabled">
                                    <v-progress-circular
                                    v-if="loadingReferrals"
                                    indeterminate
                                    color="rgb(151, 138, 206)"
                                    class="mt-0"
                                    size="17"
                                    />
                                    <v-tooltip bottom v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <p v-bind="attrs" v-on="on" style="cursor: pointer;" class="mb-0" @click="copy()"
                                            v-clipboard:copy="'https://harmony.fan/?referral=' + getUserEarningReferrals.code">
                                            {{'harmony.fan/?referral=' +  getUserEarningReferrals.code }}
                                                <v-icon
                                                class="ml-2 mb-1"
                                                size="17"
                                                color="#8e84c0"
                                                >
                                                mdi-content-copy
                                                </v-icon>
                                            </p>
                                        </template>
                                        <span>{{ $t('referral.copy') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-list>
                    <v-list class="col-12 col-sm-6 py-0 py-sm-1">
                        <v-col>
                            <v-row justify="center" class="text-left">
                                <v-col cols="3" sm="3" md="3" class="text-label">
                                    {{ $t('referral.title') }}
                                </v-col>
                                <v-col cols="6" sm="6" md="9"
                                    class="text-right text-sm-left text-container text-disabled">
                                    <v-progress-circular
                                    v-if="loadingReferrals"
                                    indeterminate
                                    color="rgb(151, 138, 206)"
                                    class="mt-0"
                                    size="17"
                                    />
                                    <template v-else>
                                        {{ getUserEarningReferrals.referral_count }}
                                    </template>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-list>
                </div>
            </v-row>
        </div>

        <template>
            <v-col cols="12" class="pa-0 m-0">
                <v-tabs v-model="activeTab" color="#8e84c0"
                    class="round-tab mb-6 d-flex justify-left ml-4">
                    <v-tab v-for="(tab, index) in tabs" :key="index" class="custom-tab">
                        {{ tab }}
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-responsive class="scroll-data pa-5 pt-0">
                <v-data-table v-show="activeTab === 0" :headers="headers" :items="earnings_referrals" :loading="loadingEarningsReferrals" class="elevation-0 card rounded-xl pa-3">
                    <template v-slot:item.email="{ item }">
                        {{ truncateEmail(item.email)}}
                    </template>
                </v-data-table>
                <v-data-table v-show="activeTab === 1" :headers="headersUsersReferrals" :items="getUsersReferrals" :loading="loadingReferrals" class="elevation-0 card rounded-xl pa-3">
                    <template v-slot:item.email="{ item }">
                        {{ truncateEmail(item.email)}}
                    </template>
                </v-data-table>
            </v-responsive>
        </template>
    </v-container>
</template>

<script>
import axios from "@/axios.js";
export default {
    data(){
        return{
            activeTab: 0,
            tabs: ['Ganancias', 'Referidos'],
            earnings_referrals:[],
            getUserEarningReferrals:[],
            getUsersReferrals:[],
            show1: false,
            page: 1,
            headers:[
                { text: 'Harmony', value: 'site_name', align: 'start' },
                { text: 'Creador', value: 'email', align: 'start' },
                { text: 'Ganacias', value: 'total_amount', align: 'start' },
                { text: 'Fecha', value: 'date_earning_referral', align: 'start' },
            ],
            headersUsersReferrals:[
                {text: 'Nombre', value: 'full_name', align: 'start'},
                {text: 'Email', value: 'email', align: 'start' },
                {text: 'Fecha', value: 'created_at', align: 'start' },
            ],
            loadingReferrals:false,
            loadingEarningsReferrals:false,
        }
    },
    created(){
        this.earningsReferrals();
        this.getUser();
        this.getReferrals();
    },
    methods: {
        async earningsReferrals(){
            this.loadingEarningsReferrals = true;
            try{
                const response = await axios.get('/earnings-referrals');
                this.earnings_referrals = response.data.earnings;
            }catch (error) {
                console.error(error);
            } finally {
                this.loadingEarningsReferrals = false;
            }
        },
        async getUser(){
           const response = await axios.get('/get-user-earning-referrals');
           this.getUserEarningReferrals = response.data.getUserEarningReferrals[0];
        },
        async getReferrals(){
            this.loadingReferrals = true;
            try{
                const response = await axios.get('/get-users-referrals');
                this.getUsersReferrals = response.data.getUsersReferrals;
            }catch (error) {
                console.error(error);
            } finally {
                this.loadingReferrals = false;
            }
        },
        copy() {
            this.$noty.info("<b>Enlace de referido</b> copiado correctamente", {
                killer: true,
                timeout: 3000,
                layout: 'bottomCenter',
                animation: {
                    open: 'animated slideInUp',
                    close: 'animated fadeOut'
                }
            })
        },
        truncateEmail(email) {
            const [localPart, domain] = email.split('@');
            let truncatedLocalPart;
            if (localPart.length >= 3) {
                truncatedLocalPart = localPart.slice(0, 3) + '...';
            } else {
                truncatedLocalPart = localPart;
            }
            return `${truncatedLocalPart}@${domain}`;
        }

    }

}
</script>
<style scoped>
.text-label,
.settings {
    color: #8e84c0;
    font-size: small;
    font-weight: 700;
    text-wrap: nowrap;
    text-align: left
}

.settings {
    cursor: pointer;
    color: #72dd89;
    padding: 3px 0px
}

.text-container {
    color: #8a8a8a;
    font-size: small;
    font-weight: 300;
}

.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.card {
    border-radius: 10px;
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
}

.border {
    border: none;
    border-left: 5px solid #afa2e5;
}

.profile p {
    font-size: 0.7rem;
    max-width: 250px;
    margin: auto;
}

.profile img {
    font-size: 0.8rem;
    object-fit: cover;
}

.button {
    width: fit-content;
    border: none;
    padding: 10px !important;
    background: #8e84c013 !important;
    color: #8e84c0;
    cursor: pointer;
    box-shadow: none;
    margin: auto;
    font-size: small;
    font-weight: 700;
    letter-spacing: normal;
}

.img-profile {
    max-width: 100px !important;
    max-height: 100px !important;
    width: 100%;
    height: 100%;
}

.icon {
    color: rgb(151, 138, 206);
}
.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

@media (max-width:700px) and (min-width:100px){
    .scroll-data {
        height: 260px;
        overflow-y: auto;
    }
}
</style>